import ReactPaginate from "react-paginate";

import React from "react";

import { Fragment } from "react";

import { ChangeEvent } from "react";
import { DetailedHTMLProps } from "react";
import { FormEvent } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import moment from "moment";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";

import Badge from "@js/component/Badge";
import Button from "@js/component/Button";
import ChevronDownIcon from "@js/component/icon/ChevronDownIcon";
import ChevronUpIcon from "@js/component/icon/ChevronUpIcon";
import Heading from "@js/component/Heading";
import Input from "@js/component/Input";
import Select from "@js/component/Select";
import Switch from "@js/component/Switch";
import Textarea from "@js/component/Textarea";

import StoreState from "@js/store/StoreState";

import useDebounce from "@js/hook/useDebounce";

import { setOpen as setOpenAction } from "@js/actionCreators/statusActionCreators";

import * as classNames from "@css/component/MyRequestsView.module.scss";
import TruncateText from "@js/component/TruncateText";

/**
 * Request
 */
type Request = {
    activatedAt: string | null;
    advisor: {
        companyLogo: string;
        companyLogoName: string;
        companyName: string;
        companyPromo: string;
        deliveryTime: number;
        email: string;
        firstName: string;
        id: number;
        lastName: string;
        priceTiers: number[];
        reviewAverage: number;
        status: string;
        strongPoints: {
            id: number;
            message: string;
            sortOrder: number;
        }[];
        url: string;
        workAreas: number[];
    };
    area: string;
    areaBag: string;
    areaManual: string;
    areaName: string;
    buildingName: string;
    buildingOwnerAffix: string;
    buildingOwnerInitials: string;
    buildingOwnerName: string;
    buildingOwnerPhone: string;
    buildingOwnerTitle: string;
    buildingOwnerZipcode: string;
    buildingOwnerCompanyName: string;
    billingStreet: string;
    billingHouseNumber: number;
    billingHouseNumberSuffix: number;
    billingZipcode: string;
    billingCity: string;
    requestContact: {
        firstName: string;
        affix: string;
        name: string;
        email: string;
        phone: string;
    },
    buildingType: string;
    buildingTypeCategory:string;
    city: string;
    completed: string;
    conflict: {
        advisorNote?: string;
        advisorNoteAt?: string;
        buildingOwnerAgreed: boolean;
        buildingOwnerNote?: string;
        buildingOwnerNoteAt: string;
        createdAt: string;
        // reason: {
        //     code: string;
        //     name: string;
        // };
        updatedAt: string;
    };
    createdAt: string;
    deliveryTime: number;
    email: string;
    extraInfoMessage: string;
    extraInfoVisit?: string;
    houseNumber: string;
    houseNumberSuffix: string;
    id: number;
    methodology: string;
    methodologyName: string;
    orderFilename?: string;
    price: string;
    requiresQuote: boolean;
    priceTier: number;
    review: string;
    reviewMessage: string;
    reviewResponse: string;
    seenByAdvisorDate: string;
    street: string;
    workingdays: number;
};

/**
 * @type RequestRejectReasonData
 */
type RequestRejectReasonData = {
    allRequestRejectReason: {
        nodes: {
            code: string;
            name: string;
        }[];
    };
};

/**
 * @type FilterData
 */
type FilterData = {
    filter: {
        options: {
            buildingType: {
                label: string;
                value: string;
                category: string;
                areas: {
                    label: string;
                    value: string;
                    quote: boolean;
                }[];
            }[];
        };
    };
};

/**
 * @type Data
 */
type Data = RequestRejectReasonData & FilterData;

/**
 * @type MyRequestsViewProps
 */
export type MyRequestsViewProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const MyRequestsView
 */
const MyRequestsView = (props: MyRequestsViewProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const debounce = useDebounce();

    const data: Data = useStaticQuery(graphql`
        query {
            allRequestRejectReason {
                nodes {
                    code
                    name
                }
            }
            filter {
                options {
                    buildingType {
                        label
                        value
                        category
                        areas {
                            label
                            value
                            quote
                        }
                    }
                }
            }
        }
    `);

    const [open, setOpen] = useState(0);

    const [tab, setTab] = useState("new");

    const [requests, setRequests ] = useState<Request[]>([]);
    const [orderBy, setOrderBy] = useState("");
    const [orderDirection, setOrderDirection] = useState("");
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [isFetchingRequests, setIsFetchingRequests] = useState(true);
    const [search, setSearch] = useState("");
    const [actualTab, setSActualTab] = useState("new");

    // const [conflictReason, setConflictReason] = useState("");
    const [conflictExplanation, setConflictExplanation] = useState("");

    const openRequests = useSelector((storeState: StoreState) => storeState.status.open);
    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getRequests = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/search?token=${ token }`, {
            body: JSON.stringify({
                filters: {
                    search: search,
                    status: tab
                },
                orderBy: orderBy,
                orderDirection: orderDirection,
                page: page
            }),
            method: "POST"
        });

        const data = await response.json();

        setRequests(data.result);
        setPages(data.pages);
        setOpen(0);
        setIsFetchingRequests(false);

        setSActualTab(tab);
    };

    const dateTimeFormat = new Intl.DateTimeFormat("nl-NL");


    const onConflictFormSubmit = (request: Request) => async (event: FormEvent) => {
        event.preventDefault();

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/conflict/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                //"rejectReason": conflictReason,
                "advisorNote": conflictExplanation
            }),
            method: "PUT"
        });

        getRequests();
    };

    const onToggleButtonClick = (id: number) => () => {
        if (open == id) {
            setOpen(0);
        }
        else {
            setOpen(id);
        }
    };

    const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const split = event.currentTarget.value.split("-");

        setOrderBy(split[0]);
        setOrderDirection(split[1]);
    };

    const onSwitchChange = (request: Request) => () => {
        const copyOfRequests = [ ...requests ];

        const index = copyOfRequests.findIndex((r) => {
            return r == request;
        });

        let activated = true;

        if (copyOfRequests[index].activatedAt) {
            copyOfRequests[index].activatedAt = null;

            activated = false;

            dispatch(setOpenAction(openRequests + 1));
        }
        else {
            copyOfRequests[index].activatedAt = "1";

            activated = true;

            dispatch(setOpenAction(openRequests - 1));
        }

        setRequests(copyOfRequests);

        debounce(async () => {
            const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/contacted/${ request.id }?token=${ token }`, {
                body: JSON.stringify({
                    activated: activated
                }),
                method: "PUT"
            });

            getRequests();
        });
    };

    const onDeliverButtonClick = (request: Request) => async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/completed/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                completed: true
            }),
            method: "PUT"
        });

        getRequests();
    };

    const onPlannedButtonClick = (request: Request) => async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/planned/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                planned: true
            }),
            method: "PUT"
        });

        getRequests();
    };

    useEffect(() => {
        setIsFetchingRequests(true);
        setRequests([]);

        debounce(() => {
            getRequests();
        });
    }, [tab, page, search, orderBy, orderDirection]);

    useEffect(() => {
        setOpen(0);
    }, [tab]);

    useEffect(() => {
        //setConflictReason("");
        setConflictExplanation("");
    }, [open]);

    return (
        <div { ...restProps } className={ clsx(classNames.myRequestsView, className) }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="medium">Mijn aanvragen</Heading>
                <p className={ classNames.paragraph }>
                    Op deze pagina vind je de opdrachten die je via Woninglabel.nl hebt ontvangen.
                    Het getal bij ‘Mijn aanvragen’ in het hoofdscherm en linksboven in het snelmenu geeft het aantal nieuw ontvangen opdrachten weer.
                    Deze aanvragen vind je terug in het overzicht onder de tab Aangevraagd.
                    We herinneren je middels mailnotificaties er ook aan dat hier nog aanvragen openstaan.
                    Heb je enige vorm van contact een klant gelegd? Dan geef je dat gemakkelijk aan middels het schuifje in de opdracht.
                    Deze opdracht vind je daarna terug onder de tab Contact en kan je middels de knop verplaatsen naar Ingepland.
                    Heb je de klant voorzien van jouw product? Klik dan op de afmeldknop zodat wij jouw klant kunnen vragen om een review voor je te schrijven.
                    Je vindt de voltooide opdracht terug onder het tabje ‘Afgerond’. Kan de opdracht onverhoopt niet uitgevoerd worden?
                    Laat het ons weten door de reden hiervoor in de opdracht aan te geven en een wijzigingsverzoek in te dienen.
                    Doe je dit binnen 7 dagen nadat je de opdracht hebt ontvangen?
                    Dan controleren we jouw verzoek bij de klant en beoordelen we of we de aanvraag wel of niet bij je in rekening brengen.
                    Doe je dit vanaf dag 8 na aanvraag dan brengen we opdrachtvergoeding altijd in rekening.
                </p>
                <Select
                    className={ classNames.filter }
                    onChange={ (event) => setTab(event.currentTarget.value) }
                    value={ tab }
                >
                    <option value="new">Aangevraagd</option>
                    <option value="processing">Contact</option>
                    <option value="planned">Ingepland</option>
                    <option value="conflicted">Conflict</option>
                    <option value="canceled">Geannuleerd</option>
                    <option value="expired">Verlopen</option>
                    <option value="completed">Afgerond</option>
                </Select>
                <div className={ classNames.tabs }>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "new" }) }
                        onClick={ () => setTab("new") }
                    >
                        Aangevraagd
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "processing" }) }
                        onClick={ () => setTab("processing") }
                    >
                        Contact
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "planned" }) }
                        onClick={ () => setTab("planned") }
                    >
                        Ingepland
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "conflicted" }) }
                        onClick={ () => setTab("conflicted") }
                    >
                        Conflict
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "expired" }) }
                        onClick={ () => setTab("expired") }
                    >
                        Verlopen
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "canceled" }) }
                        onClick={ () => setTab("canceled") }
                    >
                        Geannuleerd
                    </button>
                    <button
                        className={ clsx(classNames.tab, { [classNames.active]: tab == "completed" }) }
                        onClick={ () => setTab("completed") }
                    >
                        Afgerond
                    </button>
                </div>
                <div className={ classNames.searchAndSort }>
                    <Input
                        className={ classNames.input }
                        name="search"
                        onChange={ (event) => {
                            setPage(1);
                            setSearch(event.currentTarget.value);
                        } }
                        placeholder="Zoek op klantnaam of adres"
                        value={ search }
                    />
                    <Select className={ classNames.sort } name="sort" onChange={ onSelectChange }>
                        <option disabled value=""> -- Sorteren -- </option>
                        <option value="createdAt-asc">Aanvraag - oplopend</option>
                        <option value="createdAt-desc">Aanvraag - aflopend</option>
                        <option value="buildingOwnerName-asc">Klant - oplopend</option>
                        <option value="buildingOwnerName-desc">Klant - aflopend</option>
                        <option value="city-asc">Woonplaats - oplopend</option>
                        <option value="city-desc">Woonplaats - aflopend</option>
                        <option value="buildingOwnerZipcode-asc">Postcode - oplopend</option>
                        <option value="buildingOwnerZipcode-desc">Postcode - aflopend</option>
                        <option value="deliveryTime-asc">Levertijd - oplopend</option>
                        <option value="deliveryTime-desc">Levertijd - aflopend</option>
                    </Select>
                </div>
                <table className={ classNames.table }>
                    <thead className={ classNames.head }>
                        <tr className={ classNames.row }>
                            <th className={ classNames.heading }>&nbsp;</th>
                            <th className={ classNames.heading }>
                                Aanvraag
                            </th>
                            <th className={ classNames.heading }>
                                Klant
                            </th>
                            <th className={ classNames.heading }>
                                Adres
                            </th>
                            { (actualTab == "new") && (
                                <th className={ classNames.heading }>
                                    Openstaand
                                </th>
                            ) }
                            { (actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                <th className={ classNames.heading }>
                                    Levertijd
                                </th>
                            ) }
                            { !(actualTab == "new" || actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                <th className={ classNames.heading }>&nbsp;</th>
                            ) }
                            <th className={ classNames.heading }>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody className={ classNames.body }>
                        { isFetchingRequests &&
                            <tr className={ classNames.row }>
                                <td className={ clsx(classNames.data, classNames.dataFetching) } colSpan={ 7 }>
                                    <div className={ classNames.fetching }>De aanvragen worden opgehaald...</div>
                                </td>
                            </tr>
                        }
                        { !(isFetchingRequests) &&
                            <>
                                { requests.length == 0 &&
                                    <tr className={ classNames.row }>
                                        <td className={ clsx(classNames.data, classNames.dataNoResults) } colSpan={ 7 }>
                                            <div className={ classNames.noResults }>Er zijn geen aanvragen gevonden.</div>
                                        </td>
                                    </tr>
                                }
                                { requests.length > 0 && requests.map((request) => {
                                    // console.log(request);
                                    const today = new Date();
                                    const createdAt = new Date(request.createdAt);

                                    const difference = (today.getTime() - createdAt.getTime());
                                    const differenceInDays = difference / (1000 * 3600 * 24);

                                    const days = Math.round(request.deliveryTime - differenceInDays);

                                    const streetAddress = `${ request.street } ${ request.houseNumber } ${ request.houseNumberSuffix ?? '' }`;
                                    const billingAddress = `${ request.billingStreet } ${ request.billingHouseNumber } ${ request.billingHouseNumberSuffix ?? '' }`;

                                    return (
                                        <Fragment key={ request.id }>
                                            <tr className={ clsx(classNames.row, {
                                                [classNames.rowOpenTop]: open == request.id
                                            }) }>
                                                <td className={ clsx(
                                                    classNames.data,
                                                    classNames.dataCustomerContact
                                                ) } >
                                                    { actualTab == "new" && (
                                                        <div
                                                            className={ clsx(classNames.contact, {
                                                                    [classNames.red]: request.activatedAt == null,
                                                                    [classNames.green]: !(request.activatedAt == null)
                                                                })
                                                            }
                                                            title={ `Klantcontact: ${request.activatedAt == null ? 'Nee' : 'Ja'}` }
                                                        />
                                                    ) }
                                                </td>
                                                <td className={ classNames.data } data-heading="Aanvraag">
                                                    { dateTimeFormat.format(createdAt) }
                                                </td>
                                                <td className={ classNames.data } data-heading="Klant">
                                                    <TruncateText
                                                        textInput={ request.buildingOwnerInitials + request.buildingOwnerAffix + request.buildingOwnerName }
                                                        textOutput={ `${ request.buildingOwnerInitials } ${ request.buildingOwnerAffix ? request.buildingOwnerAffix : '' } ${ request.buildingOwnerName }` }
                                                        count={ 22 }
                                                        />
                                                </td>
                                                <td className={ classNames.data } data-heading="Adres">
                                                    <TruncateText
                                                        textInput={ request.street + request.houseNumber + request.houseNumberSuffix }
                                                        textOutput={ `${ request.street } ${ request.houseNumber } ${ request.houseNumberSuffix ? request.houseNumberSuffix : '' }` }
                                                        count={ 25 }
                                                    />
                                                    <br />
                                                    <TruncateText
                                                        textInput={ request.buildingOwnerZipcode + request.city }
                                                        textOutput={ `${ request.buildingOwnerZipcode } ${ request.city }` }
                                                        count={ 30 }
                                                    />
                                                </td>
                                                { (actualTab == "new") && (
                                                    <td className={ classNames.data } data-heading="Openstaand">
                                                        <Badge className={ clsx(classNames.badge, {
                                                            [classNames.badgeOrange]: !(request.completed) && request.workingdays >= 1 && request.workingdays < 2,
                                                            [classNames.badgeRed]: !(request.completed) && request.workingdays >= 2 && request.workingdays < 3,
                                                            [classNames.badgeDarkRed]: !(request.completed) && request.workingdays > 3,
                                                            [classNames.badgeGrey]: request.completed
                                                        }) }>
                                                            { request.workingdays } werkdag(en)
                                                        </Badge>
                                                    </td>
                                                ) }
                                                { (actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                                    <td className={ classNames.data } data-heading="Levertijd">
                                                        <Badge className={ clsx({
                                                            [classNames.badgeRed]: !(request.completed) && days < 1,
                                                            [classNames.badgeOrange]: !(request.completed) && days == 1,
                                                            [classNames.badgeGrey]: request.completed
                                                        }) }>
                                                            { days } werkdag(en)
                                                        </Badge>
                                                    </td>
                                                ) }
                                                { !(actualTab == "new" || actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                                    <td className={ classNames.data }>&nbsp;</td>
                                                ) }
                                                <td className={ classNames.data }>
                                                    <button
                                                        className={ classNames.button }
                                                        onClick={ onToggleButtonClick(request.id) }
                                                    >
                                                        { !(open == request.id) &&
                                                            <ChevronDownIcon className={ classNames.icon } />
                                                        }
                                                        { (open == request.id) &&
                                                            <ChevronUpIcon className={ classNames.icon } />
                                                        }
                                                    </button>
                                                </td>
                                            </tr>
                                            { open == request.id &&
                                                <tr className={ clsx(classNames.row, classNames.rowOpenBottom) }>
                                                    <td
                                                        className={ clsx(classNames.data, classNames.dataColSpan) }
                                                        colSpan={ 7 }
                                                    >
                                                        <div className={ classNames.blocks }>
                                                            <div className={ classNames.blockOne }>
                                                                <div className={ classNames.informationOne }>
                                                                    <label className={ classNames.heading }>Adresgegevens gebouw</label>
                                                                    <div>
                                                                        <TruncateText
                                                                            textInput={ streetAddress }
                                                                            textOutput={ streetAddress }
                                                                            count={ 25 }
                                                                        />
                                                                        <br />
                                                                        <TruncateText
                                                                            textInput={ request.buildingOwnerZipcode + request.city }
                                                                            textOutput={ `${ request.buildingOwnerZipcode } ${ request.city }` }
                                                                            count={ 25 }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className={ classNames.informationTwo }>
                                                                    <label className={ classNames.heading }>Gegevens aanvrager</label>
                                                                    { request.buildingOwnerCompanyName &&
                                                                        <>{ request.buildingOwnerCompanyName }<br /></>
                                                                    }
                                                                    <TruncateText
                                                                        textInput={ request.buildingOwnerInitials + request.buildingOwnerAffix + request.buildingOwnerName }
                                                                        textOutput={ `${ request.buildingOwnerInitials } ${ request.buildingOwnerAffix ? request.buildingOwnerAffix : '' } ${ request.buildingOwnerName }` }
                                                                        count={ 25 }
                                                                    />
                                                                    <a href={`tel:${ request.buildingOwnerPhone }`}>
                                                                        { request.buildingOwnerPhone }
                                                                    </a>
                                                                    <a
                                                                        href={`mailto:${ request.email }`}
                                                                    >
                                                                        <TruncateText
                                                                            textInput={ request.email }
                                                                            textOutput={ request.email }
                                                                            count={ 25 }
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className={ classNames.blockTwo }>
                                                                <table className={ classNames.table }>
                                                                    <tbody className={ classNames.body }>
                                                                        <tr className={ classNames.row }>
                                                                            <th className={ classNames.heading }>
                                                                                Type gebouw
                                                                            </th>
                                                                            <td className={ classNames.data }>
                                                                                { request.buildingTypeCategory == 'private' && <>Woning<br /></> }
                                                                                { request.buildingName }
                                                                            </td>
                                                                        </tr>
                                                                        { request.areaBag &&
                                                                            <tr className={classNames.row}>
                                                                                <th className={classNames.heading}>
                                                                                    BAG-oppervlakte
                                                                                </th>
                                                                                <td className={classNames.data}>
                                                                                    { request.areaBag } m²
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        <tr className={ classNames.row }>
                                                                            <th className={ classNames.heading }>
                                                                                Oppervlakte-cat.
                                                                            </th>
                                                                            <td className={ classNames.data }>
                                                                                { data.filter.options.buildingType
                                                                                    .filter(
                                                                                        type => type.category === request.buildingTypeCategory &&
                                                                                            type.value === request.buildingType
                                                                                    )
                                                                                    .map((areaOptions) => (
                                                                                        areaOptions.areas.filter(option => option.value == request.area ).map((area) => (
                                                                                            <span key={ area.value }>
                                                                                                { area.label } m²
                                                                                            </span>
                                                                                        ))
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr className={classNames.row}>
                                                                            <th className={classNames.heading}>
                                                                                Invoer
                                                                            </th>
                                                                            <td className={classNames.data}>
                                                                                { request.areaManual ? 'Handmatig' : 'BAG' }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                { request.billingStreet &&
                                                                    <div className={ classNames.billingAddress }>
                                                                        <label className={ classNames.heading }>Factuuradres</label>
                                                                        <div>
                                                                            <TruncateText
                                                                                textInput={ billingAddress }
                                                                                textOutput={ billingAddress }
                                                                                count={ 25 }
                                                                            />
                                                                            <br />
                                                                            <TruncateText
                                                                                textInput={ request.billingZipcode + request.billingCity }
                                                                                textOutput={ `${ request.billingZipcode } ${ request.billingCity }` }
                                                                                count={ 25 }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className={ classNames.blockThree }>
                                                                <table className={ classNames.table }>
                                                                    <tbody className={ classNames.body }>
                                                                    <tr className={ classNames.row }>
                                                                        <th className={ classNames.heading }>
                                                                            Methodiek
                                                                        </th>
                                                                        <td className={ classNames.data }>
                                                                            { request.methodologyName }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={ classNames.row }>
                                                                        <th className={ classNames.heading }>Prijs</th>
                                                                        <td className={ classNames.data }>
                                                                            {request.price !== null ? '€ ' + request.price.replace('.', ',') : ''}
                                                                            {request.requiresQuote ? 'Prijsopgave' : ''}
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={ classNames.row }>
                                                                        <th className={ classNames.heading }>
                                                                            Levertijd
                                                                        </th>
                                                                        <td className={ classNames.data }>
                                                                            { request.deliveryTime } werkdag(en)
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>

                                                                { request.requestContact && request.requestContact.name &&
                                                                <div className={ classNames.requestContact }>
                                                                    <label className={ classNames.heading }>Contact voor opname</label>
                                                                    <div>
                                                                        <TruncateText
                                                                            textInput={ request.requestContact.firstName + request.requestContact.affix + request.requestContact.name }
                                                                            textOutput={ `${ request.requestContact.firstName } ${ request.requestContact.affix } ${ request.requestContact.name }` }
                                                                            count={ 25 }
                                                                        />
                                                                        <br />
                                                                        <a href={`tel:${ request.requestContact.phone }`}>
                                                                            { request.requestContact.phone }
                                                                        </a>
                                                                        <br />
                                                                        <a
                                                                            href={`mailto:${ request.requestContact.email }`}
                                                                        >
                                                                            <TruncateText
                                                                                textInput={ request.requestContact.email }
                                                                                textOutput={ request.requestContact.email }
                                                                                count={ 25 }
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        { (request.extraInfoVisit || request.extraInfoMessage || request.orderFilename) &&
                                                            <div className={ classNames.extra }>
                                                                { request.extraInfoVisit &&
                                                                <>
                                                                    <strong className={ classNames.label }>
                                                                        Wanneer schikt het jou dat de energielabel-adviseur langskomt
                                                                        voor een gebouwopname?
                                                                    </strong>
                                                                    <span>{ request.extraInfoVisit }</span>
                                                                </>
                                                                }

                                                                { request.extraInfoMessage &&
                                                                <>
                                                                    <strong className={ classNames.label }>
                                                                        Wil je nog iets kwijt?
                                                                    </strong>
                                                                    <span dangerouslySetInnerHTML={{ __html: request.extraInfoMessage }}></span>
                                                                </>
                                                                }

                                                                { request.orderFilename &&
                                                                <>
                                                                    <strong className={classNames.label}>
                                                                        Opdrachtbevestiging
                                                                    </strong>
                                                                    <a href={ `${ process.env.GATSBY_API_ADVISOR_URL }/request/${ request.id }/confirmation-pdf` } target="_blank" rel="noreferrer">
                                                                        Download opdrachtbevestiging
                                                                    </a>
                                                                </>
                                                                }
                                                            </div>
                                                        }
                                                        <div className={ classNames.actions }>
                                                            { (!(actualTab == "conflicted" || actualTab == "completed" || actualTab == "canceled" || actualTab == "expired")) && (
                                                                <div className={ classNames.contacted }>
                                                                    Contact met de klant opgenomen?
                                                                    <Switch
                                                                        checked={ !(!(request.activatedAt)) }
                                                                        className={ classNames._switch }
                                                                        onChange={ onSwitchChange(request) }
                                                                    />
                                                                </div>
                                                            ) }
                                                            { (actualTab == "processing") &&
                                                                <div className={ classNames.completed }>
                                                                    <Button onClick={ onPlannedButtonClick(request) }>
                                                                        Ingepland
                                                                    </Button>
                                                                </div>
                                                            }
                                                            { (!(actualTab == "conflicted" || actualTab == "new" || actualTab == "canceled" || actualTab == "expired" || actualTab == "completed" || actualTab == "processing")) &&
                                                                <div className={ classNames.completed }>
                                                                    <Button onClick={ onDeliverButtonClick(request) }>
                                                                        Geleverd en afronden
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                        { (actualTab == "new" || actualTab == "processing" || actualTab == "planned") && (
                                                            <form
                                                                className={ classNames.conflict }
                                                                method="POST"
                                                                onSubmit={ onConflictFormSubmit(request) }
                                                            >
                                                                <strong className={ classNames.label }>Deze aanvraag kan niet doorgaan, omdat:</strong>
                                                                {/*<Select*/}
                                                                {/*    className={ classNames.select }*/}
                                                                {/*    onChange={ (event) => setConflictReason(event.currentTarget.value) }*/}
                                                                {/*    required*/}
                                                                {/*    value={ conflictReason }*/}
                                                                {/*>*/}
                                                                {/*    <option disabled value="">-- Kies een reden --</option>*/}
                                                                {/*    { data.allRequestRejectReason.nodes.map((reason) => (*/}
                                                                {/*        <option key={ reason.code } value={ reason.code }>*/}
                                                                {/*            { reason.name }*/}
                                                                {/*        </option>*/}
                                                                {/*    )) }*/}
                                                                {/*</Select>*/}
                                                                <Textarea
                                                                    className={ classNames.textarea }
                                                                    onChange={ (event) => setConflictExplanation(event.currentTarget.value) }
                                                                    placeholder="Omschrijf de reden..."
                                                                    required
                                                                    value={ conflictExplanation }
                                                                />
                                                                <Button
                                                                    className={ classNames.conflictButton }
                                                                >
                                                                    Indienen
                                                                </Button>
                                                            </form>
                                                        ) }
                                                        { (actualTab == "conflicted" || actualTab == "expired" || actualTab == "canceled") && (
                                                            <>
                                                                { request.conflict && (
                                                                    <div className={ classNames.conflict }>
                                                                        <strong className={ classNames.label }>Deze aanvraag kan niet doorgaan, omdat:</strong>
                                                                        {/*<span>{ request.conflict.reason.name }</span>*/}
                                                                        <div className={ classNames.notes }>
                                                                            <div className={ classNames.advisorNote }>
                                                                                <div className={ classNames.label }>JIJ</div>
                                                                                <div className={ classNames.content }>
                                                                                    <time
                                                                                        className={ classNames.date }
                                                                                        dateTime={ request.conflict.advisorNoteAt }
                                                                                    >
                                                                                        { moment(request.conflict.advisorNoteAt).format(
                                                                                            "DD-MM-YYYY HH:mm"
                                                                                        ) }
                                                                                    </time><br />
                                                                                    { request.conflict.advisorNote }
                                                                                </div>
                                                                            </div>
                                                                            { request.conflict.buildingOwnerNote && (
                                                                                <div className={ classNames.buildingOwnerNote }>
                                                                                    <div className={ classNames.label }>KLANT</div>
                                                                                    <div className={ classNames.content }>
                                                                                        <time
                                                                                            className={ classNames.date }
                                                                                            dateTime={ request.conflict.buildingOwnerNoteAt }
                                                                                        >
                                                                                            { moment(request.conflict.buildingOwnerNoteAt).format(
                                                                                                "DD-MM-YYYY HH:mm"
                                                                                            ) }
                                                                                        </time><br />
                                                                                        { request.conflict.buildingOwnerNote }
                                                                                    </div>
                                                                                </div>
                                                                            ) }
                                                                        </div>
                                                                    </div>
                                                                ) }
                                                            </>
                                                        ) }
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>
                                    );
                                }) }
                            </>
                        }
                    </tbody>
                </table>
                { !(isFetchingRequests) && pages > 0 &&
                    <div className={ classNames.paginate }>
                        <ReactPaginate
                            activeLinkClassName={ classNames.active }
                            breakLinkClassName={ classNames._break }
                            containerClassName={ classNames.list }
                            disabledClassName={ classNames.disabled }
                            forcePage={ page - 1 }
                            marginPagesDisplayed={ 1 }
                            nextLinkClassName={ classNames.next }
                            nextLabel="Volgende"
                            onPageChange={ ({ selected }) => {
                                setPage(selected + 1);
                            } }
                            pageClassName={ classNames.listItem }
                            pageCount={ pages }
                            pageLinkClassName={ classNames.anchor }
                            pageRangeDisplayed={ 2 }
                            previousLinkClassName={ classNames.previous }
                            previousLabel="Vorige"
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default MyRequestsView;
